// *************************************
// BrokerageFee Responses Data Generator
// *************************************
import { getPerString, getPrcString } from "../../api/UiUtils";

/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("BrokerageFee.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_중개수수료"] > 0) {
      itemsArr.push({
        name: "중개수수료",
        value: getPrcString(resData["ret_중개수수료"] * 1.1),
        additionalInfo: "VAT 10% 포함",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("BrokerageFee.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_매매가"] > 0) {
      itemsArr.push({
        type: "data",
        name: "매매가",
        value: getPrcString(resData["ret_매매가"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_전세가"] > 0) {
      itemsArr.push({
        type: "data",
        name: "전세가",
        value: getPrcString(resData["ret_전세가"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_보증금"] > 0) {
      itemsArr.push({
        type: "data",
        name: "보증금",
        value: getPrcString(resData["ret_보증금"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_월세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "월세",
        value: getPrcString(resData["ret_월세"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_수수료_기준금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "수수료 기준금액",
        value: getPrcString(resData["ret_수수료_기준금액"]),
        additionalInfo: "보증금 + 차임 x 100",
      });
    }
    if (resData["ret_지정요율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "지정요율",
        value: getPerString(resData["ret_지정요율"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_현재까지_불입금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "현재까지 불입금액",
        value: getPrcString(resData["ret_현재까지_불입금액"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_프리미엄"] > 0) {
      itemsArr.push({
        type: "data",
        name: "프리미엄",
        value: getPrcString(resData["ret_프리미엄"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_거래가격"] > 0) {
      itemsArr.push({
        type: "data",
        name: "거래가격",
        value: getPrcString(resData["ret_거래가격"]),
        additionalInfo: "기준가격(불입금액 + 프리미엄)",
      });
    }
    if (resData["ret_중개수수료"] > 0) {
      itemsArr.push({
        type: "data",
        name: "중개수수료",
        value: getPrcString(resData["ret_중개수수료"]),
        additionalInfo: "VAT 미포함",
      });
    }
    if (resData["ret_중개수수료"] > 0) {
      itemsArr.push({
        type: "data",
        name: "중개수수료",
        value: getPrcString(resData["ret_중개수수료"] * 1.1),
        additionalInfo: "VAT 10% 포함",
      });
    }
    // itemsArr.push({
    //   type: "hr",
    // });   
  }

  return itemsArr;
}

import { useEffect, useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Form, Row, Col, Button } from "react-bootstrap";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import sendCalReq from "../../api/sendCalReq";
import Responses from "../Responses/Responses";
import getURLParamValue from "../../api/getURLParamValue";
import "../../style/global.css";
import classes from "./DepositInterest.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesDetailItems,
  getResponsesSummaryItems,
} from "../../api/ResponsesViewDataFactory/DepositInterestRVDFactory";
import { hideFormItem, showFormItem, validateMon, validatePer, validatePrc } from "../../api/UiUtils";
import { inputChangeHandler, radioSelectHandler } from "../../api/UiHandlers";

function DepositInterest() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 금리옵션 (1:단리, 2:복리)
    opt2: 1, // 세금옵션 (1:비과제, 2:일반과세, 3:기타과세)
    opt3: 1, // 예적금옵션 (1:예금, 2:적금)
    prc1: "", // 예금액
    prc2: "", // 월적금액
    mon1: "", // 기간
    per1: "", // 연이자율
    per2: "", // 세율
  });
  const [show, setShow] = useState({
    prc1: true,
    prc2: false,
    per2: false,
  });
  const [focus, setFocus] = useState({
    prc1: false,
    prc2: false,
    mon1: false,
    per1: false,
    per2: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "금액 입력";
  const monPlaceholder = "총개월수 입력";
  const perPlaceholder = "0.01~100 사이값 입력";

  useEffect(() => {
    if (optValues.opt3 === 1) {
      showFormItem("prc1", setShow, setOptValues);
      hideFormItem("prc2", setShow,  setFocus, setOptValues);
    } else {
      hideFormItem("prc1", setShow, setFocus, setOptValues);
      showFormItem("prc2", setShow, setOptValues);
    }

    if (optValues.opt2 === 3) { 
      showFormItem("per2", setShow, setOptValues);
    } else {
      hideFormItem("per2", setShow, setFocus, setOptValues);
    }
  }, [optValues.opt2, optValues.opt3]);

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const opt2 = optValues.opt2;
    const opt3 = optValues.opt3;
    const prc1 = optValues.prc1;
    const prc2 = optValues.prc2;
    const mon1 = optValues.mon1;
    const per1 = optValues.per1;
    const per2 = optValues.per2;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    // validate prc1
    reqReady &= validatePrc(show.prc1, "prc1", prc1, setFocus, setOptValues);
    // validate prc2
    reqReady &= validatePrc(show.prc2, "prc2", prc2, setFocus, setOptValues);
    // validate mon1
    reqReady &= validateMon(true, "mon1", mon1, setFocus, setOptValues);
    // validate per1
    reqReady &= validatePer(true, "per1", per1, setFocus, setOptValues);
    // validate per2
    reqReady &= validatePer(show.per2, "per2", per2, setFocus, setOptValues);

    if (!reqReady) return;

    let reqObj = {};
    reqObj["금리옵션"] = opt1;
    reqObj["세금옵션"] = opt2;
    reqObj["예적금옵션"] = opt3;
    reqObj["예금액"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10000;
    reqObj["월적금액"] = prc2 === "-1" ? -1 : parseInt(prc2) * 10000;
    reqObj["기간"] = mon1 === "-1" ? -1 : parseInt(mon1);
    reqObj["연이자율"] = per1 === "-1" ? -1 : parseFloat(per1);
    reqObj["세율"] = per2 === "-1" ? -1 : parseFloat(per1);

    sendCalReq(
      "/v1/savings_interest",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s")
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  // function handleCheckChange(name, val) {
  //   checkChangeHandler(name, val, setOptValues, true);
  // }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevOptValues) => ({
      ...prevOptValues,
      prc1: false,
      prc2: false,
      mon1: false,
      per1: false,
      per2: false,
    }));
  }

  // Respones show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 예 적 금 이 자
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="금리옵션"
              name="opt1"
              items={[
                { label: "단리", value: 1 },
                { label: "복리", value: 2 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
            />
            <FormInputRadioModule
              title="세금옵션"
              name="opt2"
              items={[
                { label: "비과세", value: 1 },
                { label: "일반과세", value: 2 },
                { label: "기타과세", value: 3 },
              ]}
              value={optValues.opt2}
              required
              onSelect={handleRadioSelect}
            />
            <FormInputRadioModule
              title="예적금옵션"
              name="opt3"
              items={[
                { label: "예금", value: 1 },
                { label: "적금", value: 2 },
              ]}
              value={optValues.opt3}
              required
              onSelect={handleRadioSelect}
            />
            {show.prc1 && (
              <FormInputNumberModule
                type="price"
                title="예금액"
                name="prc1"
                value={optValues.prc1}
                required={false}
                focused={focus.prc1}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc2 && (
              <FormInputNumberModule
                type="price"
                title="월적금액"
                name="prc2"
                value={optValues.prc2}
                required={false}
                focused={focus.prc2}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            <FormInputNumberModule
              type="month"
              title="기간"
              name="mon1"
              value={optValues.mon1}
              required={false}
              focused={focus.mon1}
              placeholder={monPlaceholder}
              onChange={handleInputChange}
            />
            <FormInputNumberModule
              type="percentage"
              title="연이자율"
              name="per1"
              value={optValues.per1}
              required={false}
              focused={focus.per1}
              placeholder={perPlaceholder}
              onChange={handleInputChange}
            />
            {show.per2 && (
              <FormInputNumberModule
                type="percentage"
                title="세율"
                name="per2"
                value={optValues.per2}
                required={false}
                focused={focus.per2}
                placeholder={perPlaceholder}
                onChange={handleInputChange}
              />
            )}
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="예 적 금 이 자"
        descArr={[
          "! 예·적금 이자 계산 결과에 대해 보장하지 않으며, 참고용으로 사용하시기 바랍니다.",

          "[ 예·적금 이자 계산 ]",

          "일정 기간을 정해놓고 은행에 맡기는 예금, 적금에 대한 이자 수익을 계산한다.",
        ]}
      />
    </>
  );
}

export default DepositInterest;

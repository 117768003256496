// *******************************************
// JeonseInsurance Responses View Data Factory
// *******************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("JeonseInsurance.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_주택금융공사_전체보증료"] > 0) {
      itemsArr.push({
        name: "주택금융공사 전체보증료",
        value: getPrcString(resData["ret_주택금융공사_전체보증료"]),
        additionalInfo:
          // "보증료율 " + getPerString(resData["ret_주택금융공사_보증료율"]),
          "기본 할인율 적용",
      });
    }
    if (resData["ret_주택도시보증공사_전체보증료"] > 0) {
      itemsArr.push({
        name: "주택도시보증공사 전체보증료",
        value: getPrcString(resData["ret_주택도시보증공사_전체보증료"]),
        additionalInfo:
          // "보증료율 " + getPerString(resData["ret_주택도시보증공사_보증료율"]),
          "기본 할인율 적용",
      });
    }
    if (resData["ret_서울보증보험_전체보증료"] > 0) {
      itemsArr.push({
        name: "서울보증보험 전체보증료",
        value: getPrcString(resData["ret_서울보증보험_전체보증료"]),
        additionalInfo:
          // "보증료율 " + getPerString(resData["ret_서울보증보험_보증료율"]),
          "기본 할인율 적용",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @return itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("JeonseInsurance.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_보증금"] > 0) {
      itemsArr.push({
        type: "data",
        name: "보증금",
        value: getPrcString(resData["ret_보증금"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_주택가액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "주택가액",
        value: getPrcString(resData["ret_주택가액"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_선순위채권"] > 0) {
      itemsArr.push({
        type: "data",
        name: "선순위채권",
        value: getPrcString(resData["ret_선순위채권"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_계약시작일자"] !== "-1") {
      itemsArr.push({
        type: "data",
        name: "계약시작일자",
        value: resData["ret_계약시작일자"],
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_계약종료일자"] !== "-1") {
      itemsArr.push({
        type: "data",
        name: "계약종료일자",
        value: resData["ret_계약종료일자"],
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_계약기간"] !== "-1") {
      itemsArr.push({
        type: "data",
        name: "계약기간",
        value: resData["ret_계약기간"] + " 일",
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_부채비율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "부채비율",
        value: getPerString(resData["ret_부채비율"]),
        additionalInfo: "(보증금 + 선순위채권) / 주택가액",
      });
    }
    // itemsArr.push({
    //   type: "hr",
    // });
    if (resData["ret_주택금융공사_보증료율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "주택금융공사 보증료율",
        value: getPerString(resData["ret_주택금융공사_보증료율"]),
        additionalInfo: "기본 할인율 적용",
      });
    }
    if (resData["ret_주택금융공사_전체보증료"] > 0) {
      itemsArr.push({
        type: "data",
        name: "주택금융공사 전체보증료",
        value: getPrcString(resData["ret_주택금융공사_전체보증료"]),
        additionalInfo: "보증금 X 보증료율",
      });
    }
    // itemsArr.push({
    //   type: "hr",
    // });
    if (resData["ret_주택도시보증공사_보증료율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "주택도시보증공사 보증료율",
        value: getPerString(resData["ret_주택도시보증공사_보증료율"]),
        additionalInfo: "기본 할인율 적용",
      });
    }
    if (resData["ret_주택도시보증공사_전체보증료"] > 0) {
      itemsArr.push({
        type: "data",
        name: "주택금융공사 전체보증료",
        value: getPrcString(resData["ret_주택도시보증공사_전체보증료"]),
        additionalInfo: "보증금 X 보증료율",
      });
    }
    // itemsArr.push({
    //   type: "hr",
    // });
    if (resData["ret_서울보증보험_보증료율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "서울보증보험 보증료율",
        value: getPerString(resData["ret_서울보증보험_보증료율"]),
        additionalInfo: "기본 할인율 적용",
      });
    }
    if (resData["ret_서울보증보험_전체보증료"] > 0) {
      itemsArr.push({
        type: "data",
        name: "서울보증보험 전체보증료",
        value: getPrcString(resData["ret_서울보증보험_전체보증료"]),
        additionalInfo: "보증금 X 보증료율",
      });
    }
  }

  return itemsArr;
}

// *******************************************
// DepositInterest Responses View Data Factory
// *******************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("DepositInterest.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_총이자액"] > 0) {
      itemsArr.push({
        name: "총 이자액",
        value: getPrcString(resData["ret_총이자액"]),
        additionalInfo: "상세 내역 참조",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("DepositInterest.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_예금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "예금액",
        value: getPrcString(resData["ret_예금액"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_월적금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "월적금액",
        value: getPrcString(resData["ret_월적금액"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_저금기간"] > 0) {
      itemsArr.push({
        type: "data",
        name: "저금기간",
        value: resData["ret_저금기간"] + " 개월",
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_총이자액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "총이자액",
        value: getPrcString(resData["ret_총이자액"]),
        additionalInfo: "예적금 테이블 참조",
      });
    }
    if (resData["ret_이자소득세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "이자소득세",
        value: getPrcString(resData["ret_이자소득세"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_세후이자"] > 0) {
      itemsArr.push({
        type: "data",
        name: "세후이자",
        value: getPrcString(resData["ret_세후이자"]),
        additionalInfo: "부가정보",
      });
    }

    // 예적금테이블
    const tableItems = resData["ret_예적금테이블"];
    if (Array.isArray(tableItems) && tableItems.length !== 0) {
      itemsArr.push({
        type: "table",
        name: "예적금 테이블 (단위:원)",
        headings: ["회차", "월적금액", "총예금액", "이자액"],
        headingMinWidths: [40, 120, 120, 120, 16],
        items: [],
      });
      tableItems.forEach((item, idx) => {
        itemsArr[itemsArr.length-1].items.push({
          "회차": tableItems[idx]["회차"].toString() + "회",
          "월적금액": getPrcString(tableItems[idx]["월적금액"], true),
          "총예금액": getPrcString(tableItems[idx]["총예금액"], true),
          "이자액": getPrcString(tableItems[idx]["이자액"], true),
        });
      });
    }
  }

  return itemsArr;
}

import { Col, Row } from "react-bootstrap";
import classes from "./DetailGuide.module.css";

function DetailGuide(props) {
  const title = props.title;
  const descArr = props.descArr;

  return (
    <>
      {/* DetailGuide { */}
      {/* <Row>
        <Col className={classes.detailGuideRecommend}>
          <div>자세한 설명이 필요하십니까?</div>
        </Col>
      </Row> */}
      <Row>
        <Col className={classes.detailGuideTitle}>{title}</Col>
      </Row>
      {/* DetailGuideItem */}
      <Row>
        <Col className={classes.detailGuideItemBg1}>
          {/* logo */}
          {false && <p className={classes.detailGuideItemLogo}>LOGO</p>}
          {/* header */}
          {false && (
            <p className={classes.detailGuideItemHeader}>중개수수료란?</p>
          )}
          {/* content */}
          {descArr.map((item, idx) => {
            const element =
              idx === 0 ? (
                <p className={classes.detailGuideWarning} key={idx}>
                  {item}
                </p>
              ) : (
                <p className={classes.detailGuideItemContent} key={idx}>
                  {item}
                </p>
              );
            return element;
          })}
        </Col>
      </Row>
      {/* } DetailGuide */}
    </>
  );
}

export default DetailGuide;

import { Col, Row } from "react-bootstrap";
import classes from "./ResponsesDetailHr.module.css";

function ReseponsesDetailHr() {
  return (
    <>
      {/* ResponsesDetailHr { */}
      <Row>
        <Col>
          <hr className={classes.responsesDetailItemHr}></hr>
        </Col>
      </Row>
      {/* } ResponsesDetailHr */}
    </>
  );
}

export default ReseponsesDetailHr;

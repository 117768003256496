import React from "react";
import {
  Navbar,
  NavDropdown,
  Container,
  Offcanvas,
  Nav,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import menus from "../menus";
import classes from "./MainMenu.module.css";

function MainMenu() {
  return (
    <Navbar fixed="top" variant="dark" expand={false} className={classes.navBar} id="mainNav">
      <Container fluid>
        <Navbar.Toggle aria-controls="realtyNavbar" />
        <Navbar.Brand href="/">집사구:땅팔구</Navbar.Brand>
        {/* <Navbar className="d-none d-lg-inline d-xl-inline d-xxl-inline">
          <Nav className="me-auto">
            {menus.categories.map((catItem, catIdx) => {
              const l1key = "a-" + catIdx;
              if (catItem.type === "category") {
                const menuEl = catItem.items.map((menuItem, menuIdx) => {
                  const l2key = "a-" + catIdx + "-" + menuIdx;
                  if (menuItem.type === "path") {
                    return (
                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={menuItem.path}
                        key={l2key}
                      >
                        <Nav.Item className="dropdown-item">
                          {menuItem.name}
                        </Nav.Item>
                      </NavLink>
                    );
                  } else if (menuItem.type === "link") {
                    return (
                      <a
                        style={{ textDecoration: "none" }}
                        href={menuItem.link}
                        target="_blank"
                        key={l2key}
                        rel="noreferrer"
                      >
                        <Nav.Item className="dropdown-item">
                          {menuItem.name}
                        </Nav.Item>
                      </a>
                    );
                  } else if (menuItem.type === "divider") {
                    return <NavDropdown.Divider key={l2key} />;
                  } else {
                    return null;
                  }
                });
                return (
                  <NavDropdown
                    title={catItem.name}
                    id={"nav-dropdown-" + (catIdx + 1)}
                    key={l1key}
                  >
                    {menuEl}
                  </NavDropdown>
                );
              } else if (catItem.type === "path") {
                return (
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={catItem.path}
                    key={l1key}
                  >
                    <Nav.Item
                      className="dropdown-item"
                      style={{ padding: "8px", color: "rgba(0,0,0,.55)" }}
                    >
                      {catItem.name}
                    </Nav.Item>
                  </NavLink>
                );
              } else if (catItem.type === "link") {
                return (
                  <a
                    style={{ textDecoration: "none" }}
                    href={catItem.link}
                    target="_blank"
                    key={l1key}
                    rel="noreferrer"
                  >
                    <Nav.Item
                      className="dropdown-item"
                      style={{ padding: "8px", color: "rgba(0,0,0,.55)" }}
                    >
                      {catItem.name}
                    </Nav.Item>
                  </a>
                );
              } else {
                return null;
              }
            })}
          </Nav>
        </Navbar> */}
        <Navbar.Offcanvas
          id="realtyNavbar"
          aria-labelledby="realtyNavbarLabel"
          placement="start"
          scroll={true}
          style={{ border: "0", width: "280px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="realtyNavbarLabel">
              MENU
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <Nav className="justify-content-end flex-grow-1 pe-3"> */}
            <Nav
              className="justify-content-end"
              style={{ marginBottom: "5rem" }}
            >
              {menus.categories.map((catItem, catIdx) => {
                const l1key = "b-" + catIdx;
                if (catItem.type === "category") {
                  const menuEl = catItem.items.map((menuItem, menuIdx) => {
                    const l2key = "b-" + catIdx + "-" + menuIdx;
                    if (menuItem.type === "path") {
                      return (
                        <NavLink
                          to={menuItem.path}
                          className={classes.drawerLink}
                          key={l2key}
                        >
                          <Nav.Item className="dropdown-item">
                            {menuItem.name}
                          </Nav.Item>
                        </NavLink>
                      );
                    } else if (menuItem.type === "link") {
                      return (
                        <a
                          href={menuItem.link}
                          className={classes.drawerLink}
                          target="_blank"
                          rel="noreferrer"
                          key={l2key}
                        >
                          <Nav.Item className="dropdown-item">
                            {menuItem.name}
                          </Nav.Item>
                        </a>
                      );
                    } else if (menuItem.type === "divider") {
                      return null;
                    } else {
                      return null;
                    }
                  });
                  return (
                    <div key={l1key}>
                      <hr />
                      <div style={{ padding: "0.25rem 0", fontWeight: "bold" }}>
                        {catItem.name}
                      </div>
                      {menuEl}
                    </div>
                  );
                } else if (catItem.type === "path") {
                  return (
                    <div key={l1key}>
                      {catIdx!==0 && <hr />}
                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={catItem.path}
                      >
                        <Nav.Item
                          className="dropdown-item"
                          style={{ padding: "0.25rem 0", fontWeight: "bold" }}
                        >
                          {catItem.name}
                        </Nav.Item>
                      </NavLink>
                    </div>
                  );
                } else if (catItem.type === "link") {
                  return (
                    <div key={l1key}>
                      <hr />
                      <a
                        style={{ textDecoration: "none" }}
                        href={catItem.link}
                        target="_blank"
                        key={l1key}
                        rel="noreferrer"
                      >
                        <Nav.Item
                          className="dropdown-item"
                          style={{ padding: "0.25rem 0", fontWeight: "bold" }}
                        >
                          {catItem.name}
                        </Nav.Item>
                      </a>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default MainMenu;

import { useEffect, useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Form, Row, Col } from "react-bootstrap";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import { radioSelectHandler } from "../../api/UiHandlers";
import "../../style/global.css";
import classes from "./J2MConversion.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";

function J2MConversion() {
  const [optValues, setOptValues] = useState({
    opt1: 1, 
    per1: "", 
    prc1: "",
    prc2: "",
    prc3: "",
  });
  const [focus, setFocus] = useState({
    per1: false,
    prc1: false,
    prc2: false,
    prc3: false,
  });
  const [disabled, setDisabled] = useState({
    per1: false,
    prc1: false,
    prc2: false,
    prc3: false,
  });

  useEffect(() => {
    switch(optValues.opt1) {
      case 1 : 
        setDisabled({
          per1: false,
          prc1: false,
          prc2: false,
          prc3: true,
        });
        break;
      case 2 :
        setDisabled({
          per1: false,
          prc1: true,
          prc2: false,
          prc3: false,
        });
        break;
      case 3 : 
        setDisabled({
          per1: true,
          prc1: false,
          prc2: false,
          prc3: false,
        });
        break;
      default :
        break; 
    }
  }, [optValues]);

  function handleSubmit(e) {
    e.preventDefault();
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleInputChange(name, val) {
    console.log(name + " changed to " + val);
    switch (name.substring(0, 3)) {
      case "prc":
      case "mon":
      case "cnt":
        val = parseInt(val) <= 0 ? "" : val;
        break;
      case "per":
        val =
          val === "0.000"
            ? ""
            : parseFloat(val) < 0 || parseFloat(val) > 100
            ? ""
            : val;
        break;
      default:
        break;
    }

    switch(name) {
      case "per1":
        setOptValues((prevOptValues) => ({ ...prevOptValues, per1: val }));
        break;
      case "prc1":
        setOptValues((prevOptValues) => ({ ...prevOptValues, prc1: val }));
        break;
      case "prc2":
        setOptValues((prevOptValues) => ({ ...prevOptValues, prc2: val}));
        break;
      case "prc3":
        setOptValues((prevOptValues) => ({ ...prevOptValues, prc3: val }));
        break;
      default: 
        break;
    }

    switch(optValues.opt1) {
      case 1:
        setOptValues((prevOptValues) => ({
          ...prevOptValues,
          prc3: calculatePrc3(prevOptValues.per1, prevOptValues.prc1, prevOptValues.prc2)
        }));
        break;
      case 2:
        setOptValues((prevOptValues) => ({
          ...prevOptValues,
          prc1: calculatePrc1(prevOptValues.per1, prevOptValues.prc2, prevOptValues.prc3)
        }));
        break;
      case 3:
        setOptValues((prevOptValues) => ({
          ...prevOptValues,
          per1: calculatePer1(prevOptValues.prc1, prevOptValues.prc2, prevOptValues.prc3)
        }));
        break;
      default:
        break;
    }
  }

  function calculatePrc3(per1, prc1, prc2) {
    if (per1 === "" || prc1 === "" || prc2 === "") return "";
    const result = parseInt(
      (parseInt(prc1) - parseInt(prc2)) * (parseFloat(per1) * 0.01) / 12
    );
    return result.toString();
  }

  function calculatePrc1(per1, prc2, prc3) {
    if (per1 === "" || prc2 === "" || prc3 === "" || parseFloat(per1) === "0")
      return "";
    const result = parseInt(
      parseFloat(prc2) + (parseInt(prc3) * 12) / parseFloat(per1 * 0.01)
    ); 
    return result.toString();
  }

  function calculatePer1(prc1, prc2, prc3) {
    if (
      prc1 === "" ||
      prc2 === "" ||
      prc3 === "" ||
      parseInt(prc1) === parseInt(prc2)
    )
      return "";
    const result = parseFloat(
      ((parseInt(prc3) * 12) / (parseInt(prc1) - parseInt(prc2))) * 100.0
    ).toFixed(2); 
    return result.toString();
  }

  function freeFocus() {
    setFocus({
      opt1: false,
      per1: false,
      prc1: false,
      prc2: false,
      prc3: false,
    });
  }

  function clearInput() {
    setOptValues((prevOptValues) => ({
      ...prevOptValues,
      per1: "",
      prc1: "",
      prc2: "",
      prc3: ""
    }));
  }
  
  // const calTitleStyle = {
  //   borderBottom: "1px solid #A3A1A1",
  //   padding: "0.9rem 0",
  //   marginTop: "0",
  //   marginBottom: "0",
  //   fontSize: "1.5rem",
  //   fontWeight: "bold",
  //   letterSpacing: "0.3rem",
  // };

  // const formStyle = {
  //   marginTop: "1.5rem",
  //   marginBottom: "0",
  //   padding: "0",
  // };

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 전 월 세 전 환
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="계산유형"
              name="opt1"
              items={[
                { label: "전세>월세", value: 1 },
                { label: "월세>전세", value: 2 },
                { label: "전환율계산", value: 3 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
              xsL={12}
              xsR={12}
            />
            <FormInputNumberModule
              type="percentage"
              title="전월세전환율"
              name="per1"
              value={optValues.per1}
              required={false}
              focused={focus.per1}
              disabled={disabled.per1}
              placeholder="전환율 입력"
              onChange={handleInputChange}
            />
            <FormInputNumberModule
              type="price"
              title="전세보증금"
              name="prc1"
              value={optValues.prc1}
              required={false}
              focused={focus.prc1}
              disabled={disabled.prc1}
              placeholder="금액입력"
              onChange={handleInputChange}
            />
            <FormInputNumberModule
              type="price"
              title="월세보증금"
              name="prc2"
              value={optValues.prc2}
              required={false}
              focused={focus.prc2}
              disabled={disabled.prc2}
              placeholder="금액입력"
              onChange={handleInputChange}
            />
            <FormInputNumberModule
              type="price"
              title="월세"
              name="prc3"
              value={optValues.prc3}
              required={false}
              focused={focus.prc3}
              disabled={disabled.prc3}
              placeholder="금액입력"
              onChange={handleInputChange}
            />
          </Form>
        </Col>
      </Row>
      <DetailGuide
        title="전 월 세 전 환"
        descArr={[
          "! 전월세 전환 금액에 대하여 보장하지 않으니 참고용으로만 확인 바랍니다. 전월세전환율은 상황에 따라 변동되기 때문에 확인하여 계산하시기 바랍니다.",

          "[ 전월세 전환 계산 ]",

          "임대차계약 기간 중이거나 재계약 시 임대인이 전세 -> 월세 또는 월세 -> 전세로 전환하는 경우, 전월세 전환율 통하여 합리적인 금액을 산정 할 수 있다.",

          "[ 전월세전환율 ]",

          "전세를 월세로 전환할 때 적용되는 비율이다. 주택임대차보호법 제7조의 2에 따라 산정률이 제한되며 2가지 기준이 있다. 1. 한국은행에서 공시한 기준금리에 대통령령으로 정하는 이율을 더한 비율, 2. 은행에서 적용하는 대출금리와 해당 지역의 경제 여건 등을 고려하여 대통령령으로 정하는 비율로 계산된다.",
        ]}
      />
    </>
  );
}

export default J2MConversion;

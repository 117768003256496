import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsBuilding } from "react-icons/bs";
import getURLParamValue from "../../api/getURLParamValue";
import sendCalReq from "../../api/sendCalReq";
import { checkChangeHandler, inputChangeHandler } from "../../api/UiHandlers";
import { validatePrc } from "../../api/UiUtils";
import FormInputCheckModule from "../FormItems/FormInputCheckModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import Responses from "../Responses/Responses";
import "../../style/global.css";
import classes from "./StampTax.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesSummaryItems,
  getResponsesDetailItems,
} from "../../api/ResponsesViewDataFactory/StampTaxRVDFactory";

function StampTax() {
  const [optValues, setOptValues] = useState({
    chk1: false,
    chk2: false,
    prc1: "",
  });
  const [focus, setFocus] = useState({
    prc1: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "1만원이상 금액입력";

  function handleSubmit(e) {
    const chk1 = optValues.chk1;
    const chk2 = optValues.chk2;
    const prc1 = optValues.prc1;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    // validate prc1
    reqReady &= validatePrc(true, "prc1", prc1, setFocus, setOptValues);

    if (!reqReady) return;

    console.log("submit");

    let reqObj = {};
    reqObj["주택소유권이전"] = chk1;
    reqObj["연대납부"] = chk2;
    reqObj["거래금액"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10_000;

    sendCalReq(
      "/v1/stamp_tax",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s")
    );
  }

  function handleCheckChange(name, val) {
    checkChangeHandler(name, val, setOptValues, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevFocus) => ({
      ...prevFocus,
      prc1: false,
    }));
  }

  // Responses show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 인 지 세
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputCheckModule
              title="주택소유권이전"
              name="chk1"
              label=""
              onChange={handleCheckChange}
              xsL={6}
              xsR={6}
            />
            <FormInputCheckModule
              title="연대납부"
              name="chk2"
              label=""
              onChange={handleCheckChange}
              xsL={6}
              xsR={6}
            />
            <FormInputNumberModule
              type="price"
              title="거래금액"
              name="prc1"
              value={optValues.prc1}
              required={false}
              focused={focus.prc1}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
            />
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="인 지 세"
        descArr={[
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'",

          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
        ]}
      />
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Request Data</p>
          <pre>{reqData && JSON.stringify(reqData, null, 4)}</pre>
        </Col>
      </Row>
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Response Data</p>
          {resData && <pre>{JSON.stringify(resData, null, 4)}</pre>}
          {error && errorString}
        </Col>
      </Row>
    </>
  );
}

export default StampTax;

import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import classes from "./FormInputCheckModule.module.css";

function FormInputCheckModule(props) {
  const title = props.title;
  const name = props.name;
  const label = props.label;
  const xsL = props.xsL ? props.xsL : 4;
  const xsR = props.xsR ? props.xsR : 8;
  const mdL = props.mdL ? props.mdL : 4;
  const mdR = props.mdR ? props.mdR : 8;
  const noHr = props.noHr;

  function handleChange(e) {
    props.onChange(name, e.target.checked)
  }

  return (
    <>
      <Row className={classes.row}>
        <Col xs={xsL} md={mdL} className={classes.col1}>
          <Form.Label className={classes.label1}>
            <strong>{title}</strong>
          </Form.Label>
        </Col>
        <Col xs={xsR} md={mdR} className={classes.col2}>
          <Form.Check className={classes.checkbox} inline label={label} name={name} type="switch" autoComplete="off" onChange={handleChange}/>
        </Col>
      </Row>
      {!noHr && (<Row>
        <Col>
          <hr className={classes.hr} />
        </Col>
      </Row>)}
    </>
  );
}

export default FormInputCheckModule;

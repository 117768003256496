import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import classes from "./Responses.module.css";
import ResponsesDetail from "./ResponsesDetail";
import ResponsesSummary from "./ResponsesSummary";

function Responses(props) {

  const show = props.show;
  const resData = props.resData;
  const summaryItems = props.getResponsesSummaryItems(resData);
  const detailItems = props.getResponsesDetailItems(resData);

  return (
    <>
      {/* Responses { */}
      {show && (
        <>
          <Row className={classes.responses}>
            <ResponsesSummary items={summaryItems} />
          </Row>
          <ResponsesDetail items={detailItems} />
        </>
      )}
      {/* } Responses */}
    </>
  );
}

export default Responses;

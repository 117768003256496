import { useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Form, Row, Col } from "react-bootstrap";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import classes from "./P2MConversion.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";

function P2MConversion() {
  const [optValues, setOptValues] = useState({
    are1: "", // 
    are2: "",
  });
  const [focus, setFocus] = useState({
    are1: false,
    are2: false,
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  function handleInputChange(name, val) {
    console.log(name + " changed to " + val);
    switch (name.substring(0, 3)) {
      case "prc":
      case "mon":
      case "cnt":
        val = parseInt(val) <= 0 ? "" : val;
        break;
      case "per":
        val =
          val === "0.000"
            ? ""
            : parseFloat(val) < 0 || parseFloat(val) > 100
            ? ""
            : val;
        break;
      case "are":
        val =
          val === "0.000" 
          ? "" 
          : parseFloat(val) < 0
          ? ""
          : val;
        break;
      default:
        break;
    }

    switch(name) {
      case "are1":
        setOptValues((prevOptValues) => ({ 
          are1: val,
          are2: calculateAre2(val),
        }));
        break;
      case "are2":
        setOptValues((prevOptValues) => ({ 
          are1: calculateAre1(val), 
          are2: val,
        }));
        break;
      default:
        break;
    }
  }

  function calculateAre2(are1) {
    if (are1 === "")
      return "";
    const result = parseFloat(parseFloat(are1) * 3.30579).toFixed(3);
    return result.toString();
  }

  function calculateAre1(are2) {
    if (are2 === "")
      return "";
    const result = parseFloat(parseFloat(are2) * 0.3025).toFixed(3);
    return result.toString();
  }

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 평 수 환 산
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputNumberModule
              type="pyeong"
              title=""
              name="are1"
              value={optValues.are1}
              required={false}
              focused={focus.are1}
              placeholder="면적 입력"
              onChange={handleInputChange}
              xsL="0"
              xsR="12"
              mdL="0"
              mdR="12"
              noL
            />
            <FormInputNumberModule
              type="m2"
              title=""
              name="are2"
              value={optValues.are2}
              required={false}
              focused={focus.are2}
              placeholder="면적 입력"
              onChange={handleInputChange}
              xsL="0"
              xsR="12"
              mdL="0"
              mdR="12"
              noL
            />
          </Form>
        </Col>
      </Row>
      <DetailGuide
        title="평 수 환 산"
        descArr={[
          "[ 평수 환산 ]",

          "대상이 되는 면적 표시 단위인 평에 대해 m2(제곱미터)로 계산한다.",
        ]}
      />
    </>
  );
}

export default P2MConversion;

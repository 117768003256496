import axios from "axios";

/**
 * sendCalReq
 * 
 * @param {string} uri 
 * @param {object} reqObj 
 * @param {function} setReqData 
 * @param {function} setResData 
 * @param {function} setLoading 
 * @param {function} setError 
 */
const sendCalReq = async (uri, reqObj, setReqData, setResData, setLoading, setError) => {
  const api_url = process.env.REACT_APP_API_URL;
  console.log('[API URL] ' + api_url);

  setReqData(reqObj);

  console.log("<< request >>");
  console.log(reqObj);

  try {
    setError(null);
    setResData(null);
    setLoading(true);
    const response = await axios.post(api_url+uri, {
      ...reqObj
    });
    setResData(response.data);
    console.log("<< response >>");
    console.log(response);
  } catch(e) {
    console.log("<< error >>");
    console.log(e);
    setError(e);
  }
  setLoading(false);
}

export default sendCalReq;

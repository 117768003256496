import { Form, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import classes from "./FormInputDateModule.module.css";

function FormInputDateModule(props) {
  const title = props.title;
  const name = props.name;
  const value = props.value;
  const required = props.required ? true : false;
  const focused = props.focused;
  const placeholder = props.placeholder;
  const xsL = props.xsL ? props.xsL : 4;
  const xsR = props.xsR ? props.xsR : 8;
  const mdL = props.mdL ? props.mdL : 4;
  const mdR = props.mdR ? props.mdR : 8;
  const noHr = props.noHr;

  function handleChange(e) {
    props.onChange(name, e.target.value);
  }

  return (
    <>
      <Row className={classes.row}>
        <Col xs={xsL} md={mdL} className={classes.col1}>
          <Form.Label className={classes.label}>
            <strong className={focused ? classes.focusedTitle : null}>{title}</strong>
          </Form.Label>
        </Col>
        <Col xs={xsR} md={mdR} className={classes.col2}>
          <InputGroup>
            <FormControl
              type="date"
              name={name}
              value={value}
              required={required}
              placeholder={placeholder}
              className={
                focused
                  ? classes.focusedInput
                  : classes.input
              }
              onChange={handleChange}
            />
          </InputGroup>
        </Col>
      </Row>
      {!noHr && (
        <Row>
          <Col>
            <hr className={classes.hr} />
          </Col>
        </Row>
      )}
    </>
  );
}

export default FormInputDateModule;

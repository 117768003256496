import * as moment from "moment";

/**
 * validatePrc
 * 
 * @param {boolean} show 
 * @param {string} name 
 * @param {string} val 
 * @param {function} setFocus 
 * @param {functin} setOptValues 
 * @returns {boolean} reqReady
 */
export function validatePrc(show, name, val, setFocus, setOptValues) {
  let reqReady;

  if (show && (val === "" || val === null || parseInt(val) <= 0)) {
    setFocus((prevFocus) => { 
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = true;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-1A)");
      }
      return tmpFocus; 
    });
    setOptValues((prevOptValues) => {
      const tmpOptValues = { ...prevOptValues };
      if (name in tmpOptValues) {
        tmpOptValues[name] = "";
      } else {
        console.log("[warning] " + name + " is not in optValues (UiUtils-1B)");
      }
      return tmpOptValues;
    });
    reqReady = false;
  } else {
    setFocus((prevFocus) => {
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = false;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-1C)");
      }
      return tmpFocus;
    });
    reqReady = true;
  }

  return reqReady;
}

/**
 * validateMon
 * 
 * @param {boolean} show 
 * @param {string} name 
 * @param {string} val 
 * @param {function} setFocus 
 * @param {functin} setOptValues 
 * @returns {boolean} reqReady
 */
export function validateMon(show, name, val, setFocus, setOptValues) {
  let reqReady;

  if (show && (val === "" || val === null || parseInt(val) <= 0)) {
    setFocus((prevFocus) => { 
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = true;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-2A)");
      }
      return tmpFocus; 
    });
    setOptValues((prevOptValues) => {
      const tmpOptValues = { ...prevOptValues };
      if (name in tmpOptValues) {
        tmpOptValues[name] = "";
      } else {
        console.log("[warning] " + name + " is not in optValues (UiUtils-2B)");
      }
      return tmpOptValues;
    });
    reqReady = false;
  } else {
    setFocus((prevFocus) => {
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = false;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-2C)");
      }
      return tmpFocus;
    });
    reqReady = true;
  }

  return reqReady;
}

/**
 * validatePer
 * 
 * @param {boolean} show 
 * @param {string} name 
 * @param {string} val 
 * @param {function} setFocus 
 * @param {functin} setOptValues 
 * @returns {boolean} reqReady
 */
export function validatePer(show, name, val, setFocus, setOptValues) {
  let reqReady;

  if (show && (val === "" || val === null || parseFloat(val) <= 0 || parseFloat(val) > 100)) {
    setFocus((prevFocus) => {
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = true;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-3A)");
      }
      return tmpFocus;
    });
    setOptValues((prevOptValues) => {
      const tmpOptValues = { ...prevOptValues };
      if (name in tmpOptValues) {
        tmpOptValues[name] = "";
      } else {
        console.log("[warning] " + name + " is not in optValues (UiUtils-3B)");
      }
      return tmpOptValues;
    });
    reqReady = false;
  } else {
    setFocus((prevFocus) => {
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = false;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-3C)");
      }
      return tmpFocus;
    });
    reqReady = true;
  }

  return reqReady;
}

/**
 * validateDat
 * 
 * @param {boolean} show 
 * @param {string} name 
 * @param {string} val 
 * @param {function} setFocus 
 * @param {function} setOptValues 
 * @returns 
 */
export function validateDat(show, name, val, setFocus, setOptValues) {
  let reqReady;

  // dat1
  if (show && (!moment(val, "YYYY-MM-DD", true).isValid())) {
    setFocus((prevFocus) => {
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = true;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-4A)");
      }
      return tmpFocus;
    });
    setOptValues((prevOptValues) => {
      const tmpOptValues = { ...prevOptValues };
      if (name in tmpOptValues) {
        tmpOptValues[name] = "";
      } else {
        console.log("[warning] " + name + " is not in optValues (UiUtils-4B)");
      }
      return tmpOptValues;
    });
    reqReady = false;
  } else {
    setFocus((prevFocus) => {
      const tmpFocus = { ...prevFocus };
      if (name in tmpFocus) {
        tmpFocus[name] = false;
      } else {
        console.log("[warning] " + name + " is not in focus (UiUtils-4C)");
      }
      return tmpFocus;
    });
    reqReady = true;
  }

  return reqReady;
}

/**
 * showFormItem
 * 
 * @param {string} name 
 * @param {function} setShow 
 * @param {function} setOptValues
 */
export function showFormItem(name, setShow, setOptValues) {
  setShow((prevShow) => {
    let tmpShow = { ...prevShow };
    if (name in tmpShow) {
      tmpShow[name] = true;
    } else {
      console.log("[warning] " + name + " is not in show (UiUtils-5A)");
    }
    return tmpShow;
  });
  // hide -> show 변화시 디폴트 값으로 세팅
  setOptValues((prevOptValues) => {
    let tmpOptValues = { ...prevOptValues };
    if (name in tmpOptValues) {
      tmpOptValues[name] = 
        (tmpOptValues[name] === -1) ? 1 : 
        (tmpOptValues[name] === "-1") ? "" : tmpOptValues[name];
    } else {
      console.log("[warning] " + name + " is not in optValues (UiUtils-5B")
    }
    return tmpOptValues;
  }); 
}

/**
 * hideFormItem
 * 
 * @param {string} name 
 * @param {function} setShow 
 * @param {function} setFocus 
 * @param {function} setOptValues
 */
export function hideFormItem(name, setShow, setFocus, setOptValues) {
  setShow((prevShow) => {
    let tmpShow = { ...prevShow };
    if (name in tmpShow) {
      tmpShow[name] = false;
    } else {
      console.log("[warning] " + name + " is not in show (UiUtils-6A)");
    }
    return tmpShow;
  });
  setFocus((prevFocus) => {
    let tmpFocus = { ...prevFocus };
    if (name in tmpFocus){
       tmpFocus[name] = false;
    } else {
      console.log("[warning] " + name + " is not in focus (UiUtils-6B)");
    }
    return tmpFocus;
  });
  // hide 시 -1, "-1", false 값으로 세팅
  setOptValues((prevOptValues) => {
    let tmpOptValues = { ...prevOptValues };
    if (name in tmpOptValues) {
      switch (name.substring(0, 3)) {
        case "opt": 
          tmpOptValues[name] = -1; break;
        case "chk":
          tmpOptValues[name] = false; break;
        case "prc":
        case "mon":
        case "cnt":
        case "per":
        case "are": 
          tmpOptValues[name] = "-1"; break;
        default: break;
      }
    } else {
      console.log("[warning] " + name + " is not in optValues (UiUtils-6C)");
    }
    return tmpOptValues;
  });
}

/**
 * 
 * @param {integer} prc 
 * @param {boolean} noSymbol
 * @returns prcString
 */
export function getPrcString(prc, noSymbol=false) {
  const regEx = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
  const prcString = noSymbol
    ? parseInt(prc).toString().replace(regEx, ",")
    : "₩ " + parseInt(prc).toString().replace(regEx, ",");
  return prcString;
}

/**
 * 
 * @param {integer} per 
 * @returns perString
 */
export function getPerString(per) {
  const perString = per.toString() + " %";
  return perString;
}

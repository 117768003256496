import { useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Form, Row, Col, Button } from "react-bootstrap";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import FormHrModule from "../FormItems/FormHrModule";
import "../../style/global.css";
import classes from "./AreaRatio.module.css";
import Responses from "../Responses/Responses";
import DetailGuide from "../DetailGuide/DetailGuide";

function AreaRatio() {
  const [optValues, setOptValues] = useState({
    are1: "", // 대지 면적
    
  });
  const [focusAre1, setFocusAre1] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
  }

  function handleInputChange(name, value) {}

  function freeFocus() {}

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 건 폐 율 · 용 적
            율
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputNumberModule
              type="m2"
              title="대지 면적"
              name="are1"
              value={optValues.are1}
              required={false}
              focused={focusAre1}
              placeholder="면적 입력"
              onChange={handleInputChange}
            />
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button variant="outline-dark">+ 건물추가</Button>
              </Col>
              <Col className="d-grid gap-2">
                <Button variant="outline-dark">- 건물삭제</Button>
              </Col>
            </Row>
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={false}
        resData={{}}
        getResponsesSummaryItems={() => {}}
        getResponsesDetailItems={() => {}}
      />
      <DetailGuide
        title="건 폐 율, 용 적 률"
        descArr={[
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'",

          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
        ]}
      />
      <Row className="testData"></Row>
    </>
  );
}

export default AreaRatio;

import { Col, Row } from "react-bootstrap";
import { BsShareFill, BsFileImage, BsPlusCircle } from "react-icons/bs";
import classes from "./ResponsesSummary.module.css";
import ResponsesSummaryItem from "./ResponsesSummaryItem";

function ResponsesSummary(props) {
  const items = !props.items ? [] : props.items;

  return (
    <>
      {/* ResponsesSummary { */}
      {items.map((item, idx) => {
        return (
          <ResponsesSummaryItem
            key={idx}
            name={item.name}
            value={item.value}
            additionalInfo={item.additionalInfo}
          />
        );
      })}
      <Row>
        <Col>
          <hr className={classes.responsesSummaryHr} />
        </Col>
      </Row>
      {/* <Row>
        <Col className={classes.responsesSummaryButtonGroup}>
          <a href="#">
            <BsShareFill />
          </a>
          <a href="#">
            <BsFileImage />
          </a>
          <a href="#">
            <BsPlusCircle />
          </a>
        </Col>
      </Row> */}
      {/* } ResponsesSummary */}
    </>
  );
}

export default ResponsesSummary;

import { Form, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import classes from "./FormInputNumberModule.module.css";

function FormInputNumberModule(props) {
  const typeString = (props.type === 'price'     ) ? '만원' : 
                     (props.type === 'percentage') ? '%'    : 
                     (props.type === 'month'     ) ? '개월' : 
                     (props.type === 'count'     ) ? '회'   : 
                     (props.type === 'pyeong'    ) ? '평'   : 
                     (props.type === 'm2'        ) ? 'm²'   : 
                     '';
  const title = props.title;
  const name = props.name;
  const value = props.value;
  const required = props.required ? true : false;
  const focused = props.focused;
  const disabled = props.disabled ? true : false;
  const placeholder = !disabled ? props.placeholder : "";
  const xsL = props.xsL ? props.xsL : 4;
  const xsR = props.xsR ? props.xsR : 8;
  const mdL = props.mdL ? props.mdL : 4;
  const mdR = props.mdR ? props.mdR : 8;
  const noHr = props.noHr;
  const yesL = !props.noL;

  function handleChange(e) {
    props.onChange(name, e.target.value);
  }

  return (
    <>
      <Row className={classes.row}>
        {yesL && (
          <Col xs={xsL} md={mdL} className={classes.col1}>
            <Form.Label className={classes.label}>
              <strong className={focused ? classes.focusedTitle : null}>
                {title}
              </strong>
            </Form.Label>
          </Col>
        )}
        <Col xs={xsR} md={mdR} className={classes.col2}>
          <InputGroup className={classes.inputGroup1}>
            <FormControl
              type="number"
              name={name}
              value={value}
              required={required}
              placeholder={placeholder}
              className={focused ? classes.focusedInput : classes.input}
              onChange={handleChange}
              disabled={disabled}
            />
            <InputGroup.Text
              className={focused ? classes.focusedInputUnit : classes.inputUnit}
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                {typeString}
              </div>
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      {!noHr && (
        <Row>
          <Col>
            <hr className={classes.hr} />
          </Col>
        </Row>
      )}
    </>
  );
}

export default FormInputNumberModule;

import { Col, Row } from "react-bootstrap";
import classes from "./ResponsesDetailItem.module.css";

function ResponsesDetailItem(props) {
  const name = props.name;
  const value = props.value;
  const additionalInfo = props.additionalInfo;

  return (
    <>
      {/* ResponseDetailItem { */}
      {name && value && (
        <Row>
          <Col xs="6" className={classes.reponsessDetailItemName}>
            {name}
          </Col>
          <Col xs="6" className={classes.responsesDetailItemValue}>
            {value}
          </Col>
        </Row>
      )}
      {additionalInfo && (
        <Row>
          <Col className={classes.responsesDetailItemAdditionalInfo}>
            {additionalInfo}
          </Col>
        </Row>
      )}
      {/* } ResponseDetailItem */}
    </>
  );
}

export default ResponsesDetailItem;

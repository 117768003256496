/**
 * radioSelectHandler
 * 
 * @param {string} name 
 * @param {string} val 
 * @param {function} setOptValues 
 * @param {function} setFocus 
 * @param {boolean} debugOn 
 */
export function radioSelectHandler(
  name,
  val,
  setOptValues,
  setFocus,
  debugOn = false
) {
  debugOn && console.log(name + " changed to " + val);

  val = parseInt(val);
  setOptValues((prevOptValues) => {
    // if (prevOptValues[name] !== val) {
    //   freeFocus();
    // }
    const tmpOptValues = { ...prevOptValues };
    if (name in tmpOptValues) {
      tmpOptValues[name] = val;
    } else {
      console.log("[warning] " + name + " is not in optValues (UiHandler-1A)");
    }
    return tmpOptValues;
  });
  setFocus((prevFocus) => {
    const tmpFocus = { ...prevFocus };
    if (name in tmpFocus) {
      tmpFocus[name] = false;
    } else {
      console.log("[warning] " + name + " is not in focus (UiHandler-1B)");
    }
    return tmpFocus;
  });
}

/**
 * checkChangeHandler
 * 
 * @param {string} name 
 * @param {string} val 
 * @param {function} setOptValues 
 * @param {boolean} debugOn 
 */
export function checkChangeHandler(
  name,
  val,
  setOptValues,
  debugOn = false
) {
  if (debugOn) console.log(name + " changed to " + val);

  setOptValues((prevOptValues) => {
    // if (prevOptValues[name] !== val) {
    //   freeFocus();
    // }
    const tmpOptValues = { ...prevOptValues };
    if (name in tmpOptValues) {
      tmpOptValues[name] = val;
    } else {
      console.log("[warning] " + name + " is not in optValues (UiHandler-2A)");
    }
    return tmpOptValues;
  });
}

/**
 * inputChangeHandler
 * 
 * @param {string} name 
 * @param {string} val 
 * @param {function} setOptValues 
 * @param {function} setFocus
 * @param {boolean} debugOn 
 */
export function inputChangeHandler(name, val, setOptValues, setFocus, debugOn = false) {
  // console.log(name + " changed to " + val);
  switch (name.substring(0, 3)) {
    case "prc":
    case "mon":
    case "cnt":
      val = parseInt(val) <= 0 ? "" : parseInt(val);
      break;
    case "per":
      val =
        val === "0.0000" ? "" : parseFloat(val) < 0 || parseFloat(val) > 100
          ? "" : val;
      break;
    case "are":
      val = val === "0.000" ? "" : parseFloat(val) < 0 ? "" : val;
      break;
    default:
      break;
  }

  if (debugOn) console.log(name + " changed to " + val);

  setOptValues((prevOptValues) => {
    if ((prevOptValues[name] !== val)) {
      // freeFocus();
      setFocus((prevFocus) => {
        let tmpFocus = { ...prevFocus };
        if (name in tmpFocus) {
          if (prevFocus[name]) {
            tmpFocus[name] = false;
          }
        } else {
          console.log("[warning] " + name + " is not in focus (UiHandler-3A)");
        }        
        return tmpFocus;
      });
    }
    const tmpOptValues = { ...prevOptValues };
    if (name in tmpOptValues) {
      tmpOptValues[name] = val;
    } else {
      console.log("[warning] " + name + " is not in optValues (UiHandler-3B)");
    }
    return tmpOptValues;
  });
}

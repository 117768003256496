import { useEffect, useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Form, Row, Col, Button } from "react-bootstrap";
import FormInputCheckModule from "../FormItems/FormInputCheckModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import sendCalReq from "../../api/sendCalReq";
import Responses from "../Responses/Responses";
import getURLParamValue from "../../api/getURLParamValue";
import {
  radioSelectHandler,
  checkChangeHandler,
  inputChangeHandler,
} from "../../api/UiHandlers";
import { hideFormItem, showFormItem, validatePer, validatePrc } from "../../api/UiUtils";
import "../../style/global.css";
import classes from "./BrokerageFee.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesSummaryItems,
  getResponsesDetailItems,
} from "../../api/ResponsesViewDataFactory/BrokerageFreeRVDFactory";

function BrokerageFee() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 거래유형
    opt2: 1, // 거래대상
    chk1: false, // 지정요율적용
    prc1: "", // 매매가
    prc2: "", // 불입금액
    prc3: "", // 프리미엄
    prc4: "", // 전세가
    prc5: "", // 보증금
    prc6: "", // 월세
    per1: "", // 지정요율
  });
  const [show, setShow] = useState({
    prc1: true,
    prc2: false,
    prc3: false,
    prc4: false,
    prc5: false,
    prc6: false,
    per1: false,
  });
  const [focus, setFocus] = useState({
    opt2: false,
    prc1: false,
    prc2: false,
    prc3: false,
    prc4: false,
    prc5: false,
    prc6: false,
    per1: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "1만원이상";
  const perPlaceholder = "0.0001~100 사이값";

  useEffect(() => {
    // clearInput();
    if (optValues.opt1 === 1 && (optValues.opt2 !== -1 && optValues.opt2 !== 3)) {
      showFormItem("prc1", setShow, setOptValues);
    } else {
      hideFormItem("prc1", setShow, setFocus, setOptValues);
    }

    if (optValues.opt1 === 1 && optValues.opt2 === 3) {
      showFormItem("prc2", setShow, setOptValues);
      showFormItem("prc3", setShow, setOptValues);
    } else {
      hideFormItem("prc2", setShow, setFocus, setOptValues);
      hideFormItem("prc3", setShow, setFocus, setOptValues);
    }

    if (optValues.opt1 === 2) {
      showFormItem("prc4", setShow, setOptValues);
    } else {
      hideFormItem("prc4", setShow, setFocus, setOptValues);
    }

    if (optValues.opt1 === 3) {
      showFormItem("prc5", setShow, setOptValues);
      showFormItem("prc6", setShow, setOptValues);
    } else {
      hideFormItem("prc5", setShow, setFocus, setOptValues);
      hideFormItem("prc6", setShow, setFocus, setOptValues);
    }

    if (optValues.chk1) {
      showFormItem("per1", setShow, setOptValues);
    } else {
      hideFormItem("per1", setShow, setFocus, setOptValues);
    }
    
  }, [optValues.opt1, optValues.opt2, optValues.chk1]);

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const opt2 = optValues.opt2;
    const chk1 = optValues.chk1;
    const prc1 = optValues.prc1;
    const prc2 = optValues.prc2;
    const prc3 = optValues.prc3;
    const prc4 = optValues.prc4;
    const prc5 = optValues.prc5;
    const prc6 = optValues.prc6;
    const per1 = optValues.per1;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    if ((opt1 !== -1 && opt1 !== 1) && opt2 === 3) {
      setFocus((prevFocus) => ({ ...prevFocus, opt2: true }));
      reqReady &= false;
    } else {
      setFocus((prevFocus) => ({ ...prevFocus, opt2: false }));
    }

    // validate prc1
    reqReady &= validatePrc(show.prc1, "prc1", prc1, setFocus, setOptValues);
    // validate prc2
    reqReady &= validatePrc(show.prc2, "prc2", prc2, setFocus, setOptValues);
    // validate prc3
    reqReady &= validatePrc(show.prc3, "prc3", prc3, setFocus, setOptValues);
    // validate prc4
    reqReady &= validatePrc(show.prc4, "prc4", prc4, setFocus, setOptValues);
    // validate prc5
    reqReady &= validatePrc(show.prc5, "prc5", prc5, setFocus, setOptValues);
    // validate prc6
    reqReady &= validatePrc(show.prc6, "prc6", prc6, setFocus, setOptValues);
    // validate per1
    reqReady &= validatePer(show.per1, "per1", per1, setFocus, setOptValues);

    if (!reqReady) return;

    console.log("submit");

    let reqObj = {};
    reqObj["거래유형"] = opt1;
    reqObj["거래대상"] = opt2;
    reqObj["지정요율적용"] = chk1;
    reqObj["매매가"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10000;
    reqObj["불입금액"] = prc2 === "-1" ? -1 : parseInt(prc2) * 10000;
    reqObj["프리미엄"] = prc3 === "-1" ? -1 : parseInt(prc3) * 10000;
    reqObj["전세가"] = prc4 === "-1" ? -1 : parseInt(prc4) * 10000;
    reqObj["보증금"] = prc5 === "-1" ? -1 : parseInt(prc5) * 10000;
    reqObj["월세"] = prc6 === "-1" ? -1 : parseInt(prc6) * 10000;
    reqObj["지정요율"] = per1 === "-1" ? -1 : parseFloat(per1);

    sendCalReq(
      "/v1/brokerage_fee",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s"),
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleCheckChange(name, val) {
    checkChangeHandler(name, val, setOptValues, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevFocus) => ({
      ...prevFocus,
      opt2: false,
      prc1: false,
      prc2: false,
      prc3: false,
      prc4: false,
      prc5: false,
      prc6: false,
      per1: false,
    }));
  }
  
  function clearInput() {
    setOptValues((prevOptValues) => ({
      ...prevOptValues,
      prc1: "",
      prc2: "",
      prc3: "",
      prc4: "",
      prc5: "",
      prc6: "",
      per1: "",
    }));
  }

  // Responses show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 중 개 보 수
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="거래유형"
              name="opt1"
              items={[
                { label: "매매", value: 1 },
                { label: "전세", value: 2 },
                { label: "월세", value: 3 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
            />
            <FormInputRadioModule
              title="거래대상"
              name="opt2"
              items={
                optValues.opt1 === 1
                  ? [
                      { label: "주택", value: 1 },
                      { label: "오피스텔", value: 2 },
                      { label: "분양권", value: 3 },
                      { label: "기타", value: 4 },
                    ]
                  : [
                      { label: "주택", value: 1 },
                      { label: "오피스텔", value: 2 },
                      { label: "기타", value: 4 },
                    ]
              }
              value={optValues.opt2}
              required
              focused={focus.opt2}
              onSelect={handleRadioSelect}
            />
            <FormInputCheckModule
              title="지정요율 적용"
              name="chk1"
              label=""
              onChange={handleCheckChange}
            />
            {show.prc1 && (
              <FormInputNumberModule
                type="price"
                title="매매가"
                name="prc1"
                value={optValues.prc1}
                required={false}
                focused={focus.prc1}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc2 && (
              <FormInputNumberModule
                type="price"
                title="불입금액"
                name="prc2"
                value={optValues.prc2}
                required={false}
                focused={focus.prc2}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc3 && (
              <FormInputNumberModule
                type="price"
                title="프리미엄"
                name="prc3"
                value={optValues.prc3}
                required={false}
                focused={focus.prc3}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc4 && (
              <FormInputNumberModule
                type="price"
                title="전세가"
                name="prc4"
                value={optValues.prc4}
                required={false}
                focused={focus.prc4}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc5 && (
              <FormInputNumberModule
                type="price"
                title="보증금"
                name="prc5"
                value={optValues.prc5}
                required={false}
                focused={focus.prc5}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc6 && (
              <FormInputNumberModule
                type="price"
                title="월세"
                name="prc6"
                value={optValues.prc6}
                required={false}
                focused={focus.prc6}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.per1 && (
              <FormInputNumberModule
                type="percentage"
                title="지정요율"
                name="per1"
                value={optValues.per1}
                required={false}
                focused={focus.per1}
                placeholder={perPlaceholder}
                onChange={handleInputChange}
              />
            )}
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="중 개 수 수 료"
        descArr={[
          "! 실제 거래되는 중개보수 금액에 대해 보장하지 않습니다. 거래에따라 금액이 달라질 수 있으므로 상한요율을 참고하시어 중개인과 협의 바랍니다.",

          "[ 중개보수 계산 ]",

          "부동산 거래시 중개인에게 지불하는 금액으로 거래유형 (매매, 전세, 월세)과 거래대상(주택, 오피스텔, 분양권, 기타) 선택에 따라 수수료가 정해진다.",

          "[ 중개보수 ]",

          "중개보수는 중개업자가 부동산을 중개한 대가로 받는 보수이다. 중개업자는 임대인과 임차인 양쪽 모두에게 중개 보수료를 받게 되며, 보수료 액수에 대해서는 국토교통부가 제시하고 각 시도 조례로 정해진 요율표를 기준으로 수수료가 정해진다. 거래금액  X 상한요율 이내에서 의뢰인과 중개인이 서로 협의하여 결정한다. (단, 한도액 초과 불과)",

          "[ 중개보수 지급 ]",

          "중개보수의 지급시기는 중개업자와 중개의뢰인 간의 약정에 따르되, 약정이 없을 경우에는 거래 부동산의 거래대금 지급이 완료된 날로 한다. (공인중개사법 제27조의2 중개보수의 지급시기)",

          "중개 수수료 요율표 제공 : 한국공인중개사협회",

          "https://ssl.kar.or.kr/pinfo/brokerfee.asp",
        ]}
      />
    </>
  );
}

export default BrokerageFee;

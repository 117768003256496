import BrokerageFee from "./components/P1/BrokerageFee";
import JeonseInsurance from "./components/P1/JeonseInsurance";
import Contact from "./components/Contact";
import LoanInterest from "./components/P1/LoanInterest";
import P2MConversion from "./components/P1/P2MConversion";
import AreaRatio from "./components/P1/AreaRatio";
import J2MConversion from "./components/P1/J2MConversion";
import DepositInterest from "./components/P1/DepositInterest";

import LegalStaffFee from "./components/P2/LegalStaffFee";
import RegistrationFree from "./components/P2/RegistrationFee";
import AcquisitionTax from "./components/P2/AcquisitionTax";
import HouseBond from "./components/P2/HouseBond";
import StampTax from "./components/P2/StampTax";

const menus = {
  categories: [
    {
      name: "중개보수",
      type: "path",
      path: "/calc/brokerage-free",
      component: <BrokerageFee />,
    },
    {
      name: "전세보증보험",
      type: "path",
      path: "/calc/jeonse-insurance",
      component: <JeonseInsurance />,
    },
    {
      name: "전월세전환",
      type: "path",
      path: "/calc/j2m-conversion",
      component: <J2MConversion />,
    },
    {
      name: "평수환산",
      type: "path",
      path: "/calc/p2m-conversion",
      component: <P2MConversion />,
    },
    {
      name: "대출이자",
      type: "path",
      path: "/calc/loan-interest",
      component: <LoanInterest />,
    },
    {
      name: "예적금이자",
      type: "path",
      path: "/calc/deposit-interest",
      component: <DepositInterest />,
    },
  ],
};

export default menus;

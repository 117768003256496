import { Col, Row } from "react-bootstrap";
import classes from "./ResponsesSummaryItem.module.css";

function ResponsesSummaryItem(props) {
  const name = props.name;
  const value = props.value;
  const additionalInfo = props.additionalInfo;

  return (
    <>
      {/* ResponsesSummaryItem { */}
      {name && (
        <Row>
          <Col className={classes.responsesSummaryItemName}>{name}</Col>
        </Row>
      )}
      {value && (
        <Row>
          <Col className={classes.responsesSummrayItemValue}>{value}</Col>
        </Row>
      )}
      {additionalInfo && (
        <Row>
          <Col className={classes.responsesSummaryItemAdditionalInfo}>
            {additionalInfo}
          </Col>
        </Row>
      )}
      {/* } ResponsesSummaryItem */}
    </>
  );
}

export default ResponsesSummaryItem;

import { Form, Row, Col } from "react-bootstrap";
import classes from "./FormInputRadioModule.module.css";

function FormInputRadioModule(props) {
  const title = props.title;
  const name = props.name;
  const items = props.items;
  const value = props.value;
  const required = props.required ? true : false;
  const focused = props.focused;
  const xsL = props.xsL ? props.xsL : 4;
  const xsR = props.xsR ? props.xsR : 8;
  const mdL = props.mdL ? props.mdL : 4;
  const mdR = props.mdR ? props.mdR : 8;
  const noHr = props.noHr;

  function handleClick(e) {
    e.preventDefault();
    props.onSelect(name, e.target.dataset['value']);
  }

  return (
    <>
      <Row className={classes.row}>
        <Col xs={xsL} md={mdL} className={classes.col1}>
          <Form.Label className={classes.label1}>
            <strong className={focused ? classes.focusedTitle : null}>{title}</strong>
          </Form.Label>
        </Col>
        <Col xs={xsR} md={mdR} className={classes.col2}>
          <input
            type="hidden"
            name={name}
            value={value}
            required={required}
          />
          {items.map((item, i, items) => (
            <span key={item.label}>
              {/* <Form.Check
                inline
                label={item.label}
                name={name}
                type="radio"
                value={item.value}
                required={required}
                style={{ margin: "0", padding: "0" }}
                onClick={handleClick}
                checked={i + 1 === selected}
              /> */}

              <button
                onClick={handleClick}
                className={
                  item.value === value
                    ? classes.itemSelected
                    : focused
                    ? classes.itemUnselectedFocused
                    : classes.itemUnselected
                }
                data-value={item.value}
              >
                {item.label}
              </button>
              {i + 1 !== items.length ? (
                <span className={focused ? classes.focusedOptPipe : classes.optPipe}>|</span>
              ) : (
                ""
              )}
            </span>
          ))}
          {/* <Form.Check inline label="매매" name="group1" type="radio" value={} required />
          <Form.Check inline label="전세" name="group1" type="radio" required />
          <Form.Check inline label="월세" name="group1" type="radio" required /> */}
        </Col>
      </Row>
      {!noHr && (<Row>
        <Col>
          <hr className={classes.hr} />
        </Col>
      </Row>)}
    </>
  );
}

export default FormInputRadioModule;

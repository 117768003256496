import { Col, Row, Table } from "react-bootstrap";
import classes from "./ResponsesDetailTable.module.css";

function ResponsesDetailTable(props) {
  const show = props.show;
  const name = !props.name ? "" : props.name;
  const headings = !props.headings ? [] : props.headings;
  const headingMinWidths = !props.headingMinWidths ? [] : props.headingMinWidths;
  const items = !props.items ? [] : props.items;

  return (
    <>
      {show && (
        <div className={classes.tableSection}>
          <Row>
            <Col className={classes.tableName}>{name}</Col>
          </Row>
          <Row>
            <Col>
              <Table responsive className={classes.table}>
                <thead>
                  <tr>
                    {headings.map((heading, idx) => (
                      <th key={`hc-${idx}`} className={classes.tableHeading} style={{minWidth: `${headingMinWidths[idx]}px`}}>
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className={classes.tableBody}>
                  {items.map((item, idx) => (
                    <tr key={`dr-${idx}`}>
                      {headings.map((heading, idx) => (
                        <td key={`dc-${idx}`} className={classes.tableData}>
                          {item[heading].toString()}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default ResponsesDetailTable;

import { Col, Row } from "react-bootstrap";
import classes from "./ResponsesDetail.module.css";
import ReseponsesDetailHr from "./ResponsesDetailHr";
import ResponsesDetailItem from "./ResponsesDetailItem";
import ResponsesDetailTable from "./ResponsesDetailTable";

function ResponsesDetail(props) {
  const items = !props.items ? [] : props.items;

  return (
    <>
      {/* ResponsesDetail { */}
      {/* <Row>
        <Col className={classes.responsesDetailRecommend}>
          <div>계산 상세 내역을 알고 싶으신가요?</div>
        </Col>
      </Row> */}
      <Row>
        <Col className={classes.responsesDetailHeader}>
          <Row>
            <Col xs="6" className={classes.responsesDetailHeaderName1}>
              세 부 항 목
            </Col>
            <Col xs="6" className={classes.responsesDetailHeaderName2}>
              <Row className={classes.responsesDetailHeaderName2r1}>
                <Col
                  xs="6"
                  className={classes.responsesDetailHeaderName2Content}
                >
                  결 과 값
                </Col>
                <Col
                  xs="6"
                  className={classes.responsesDetailHeaderName2CAdditionalInfo}
                >
                  (단위: 원)
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr className={classes.responsesDetailHeaderHr}></hr>
            </Col>
          </Row>
          {items.map((item, idx) => {
            if (item.type === "data") {
              return (
                <ResponsesDetailItem
                  key={idx}
                  name={item.name}
                  value={item.value}
                  additionalInfo={item.additionalInfo}
                />
              );
            } else if (item.type === "hr") {
              return <ReseponsesDetailHr key={idx} />;
            } else {
              return null;
            }
          })}
        </Col>
      </Row>
      {/* } ResponsesDetail */}
      <Row>
        {items.map((item, idx) => {
          if (item.type === "table") {
            const table = item;
            return (
              <ResponsesDetailTable
                key={idx}
                show={true}
                name={table.name}
                headings={table.headings}
                headingMinWidths={table.headingMinWidths}
                items={table.items}
              />
            );
          } else {
            return null;
          }
        })}
      </Row>
    </>
  );
}

export default ResponsesDetail;

import { useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Form, Row, Col, Button } from "react-bootstrap";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import FormInputCheckModule from "../FormItems/FormInputCheckModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import FormInputDateModule from "../FormItems/FormInputDateModule";
import sendCalReq from "../../api/sendCalReq";
import getURLParamValue from "../../api/getURLParamValue";
import classes from "./JeonseInsurance.module.css";
import { validateDat, validatePrc } from "../../api/UiUtils";
import { checkChangeHandler, inputChangeHandler, radioSelectHandler } from "../../api/UiHandlers";
import Responses from "../Responses/Responses";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesDetailItems,
  getResponsesSummaryItems,
} from "../../api/ResponsesViewDataFactory/JeonseInsuranceRVDFactory";

function JeonseInsurance() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 주택지역
    opt2: 1, // 주택유형
    chk1: false, // 보증료우대대상
    prc1: "", // 전세보증금
    prc2: "", // 매매시세
    prc3: "", // 선순위채권
    dat1: "", // 신청일
    dat2: "", // 계약시작일
    dat3: "", // 계약종료일
  });
  const [focus, setFocus] = useState({
    prc1: false,
    prc2: false,
    prc3: false,
    dat1: false,
    dat2: false,
    dat3: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const prcPlaceholder = "1만원이상";

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const opt2 = optValues.opt2;
    const chk1 = optValues.chk1;
    const prc1 = optValues.prc1;
    const prc2 = optValues.prc2;
    const prc3 = optValues.prc3;
    const dat1 = optValues.dat1;
    const dat2 = optValues.dat2;
    const dat3 = optValues.dat3;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    // validate price1
    reqReady &= validatePrc(true, "prc1", prc1, setFocus, setOptValues);
    // validate price2
    reqReady &= validatePrc(true, "prc2", prc2, setFocus, setOptValues);
    // validate price3
    reqReady &= validatePrc(true, "prc3", prc3, setFocus, setOptValues);
    // validate date1
    reqReady &= validateDat(true, "dat1", dat1, setFocus, setOptValues);
    // validate date2
    reqReady &= validateDat(true, "dat2", dat2, setFocus, setOptValues);
    // validate date3
    reqReady &= validateDat(true, "dat3", dat3, setFocus, setOptValues);

    if (dat1 > dat2) {
      setFocus((prevFocus) => ({ ...prevFocus, dat2: true }));
      reqReady &= false;
    }

    if (dat1 >= dat3) {
      setFocus((prevFocus) => ({ ...prevFocus, dat3: true }));
      reqReady &= false;
    }

    if (dat2 >= dat3) {
      setFocus((prevFocus) => ({ ...prevFocus, dat3: true }));
      reqReady &= false;
    }

    if (!reqReady) return;

    console.log("submit");

    let reqObj = {};
    reqObj["주택지역"] = opt1;
    reqObj["주택유형"] = opt2;
    reqObj["보증료_우대대상"] = chk1;
    reqObj["전세보증금"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10000;
    reqObj["매매시세"] = prc2 === "-1" ? -1 : parseInt(prc2) * 10000;
    reqObj["선순위채권"] = prc3 === "-1" ? -1 : parseInt(prc3) * 10000;
    reqObj["신청일"] = dat1 === "-1" ? "-1" : dat1;
    reqObj["계약시작일"] = dat2 === "-1" ? "-1" : dat2;
    reqObj["계약종료일"] = dat3 === "-1" ? "-1" : dat3;

    sendCalReq(
      "/v1/jeonse_insurance",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s")
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleCheckChange(name, val) {
    checkChangeHandler(name, val, setOptValues, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevOptValues) => ({
      ...prevOptValues,
      prc1: false,
      prc2: false,
      prc3: false,
      dat1: false,
      dat2: false,
      dat3: false,
    }));
  }

  // Respones show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 전 세 보 증 보 험
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="주택지역"
              name="opt1"
              items={[
                { label: "수도권", value: 1 },
                { label: "비수도권", value: 2 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
            />
            <FormInputRadioModule
              title="주택유형"
              name="opt2"
              items={[
                { label: "아파트·연립", value: 1 },
                { label: "단독·다중·다가구", value: 2 },
                { label: "기타", value: 3 },
              ]}
              value={optValues.opt2}
              required
              onSelect={handleRadioSelect}
              xsL={12}
              xsR={12}
            />
            <FormInputCheckModule
              title="보증료 우대대상"
              name="chk1"
              label=""
              onChange={handleCheckChange}
              xsL={6}
              xsR={6}
            />
            <FormInputNumberModule
              type="price"
              title="전세보증금"
              name="prc1"
              value={optValues.prc1}
              required={false}
              focused={focus.prc1}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
              xsL={4}
              xsR={8}
            />
            <FormInputNumberModule
              type="price"
              title="매매시세"
              name="prc2"
              value={optValues.prc2}
              required={false}
              focused={focus.prc2}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
              xsL={4}
              xsR={8}
            />
            <FormInputNumberModule
              type="price"
              title="선순위채권"
              name="prc3"
              value={optValues.prc3}
              required={false}
              focused={focus.prc3}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
              xsL={4}
              xsR={8}
            />
            <FormInputDateModule
              title="신청일"
              name="dat1"
              value={optValues.dat1}
              required={false}
              focused={focus.dat1}
              placeholder=""
              onChange={handleInputChange}
              xsL={4}
              xsR={8}
            />
            <FormInputDateModule
              title="계약시작일"
              name="dat2"
              value={optValues.dat2}
              required={false}
              focused={focus.dat2}
              placeholder=""
              onChange={handleInputChange}
              xsL={4}
              xsR={8}
            />
            <FormInputDateModule
              title="계약종료일"
              name="dat3"
              value={optValues.dat3}
              required={false}
              focused={focus.dat3}
              placeholder=""
              onChange={handleInputChange}
              xsL={4}
              xsR={8}
            />
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="전 세 보 증 보 험"
        descArr={[
          "! 실제 보증 가능 여부나 금액을 보장하지 않습니다. 전세보증보험 계산기에서는 기본 할인율이 (0.1%) 적용되어 계산됩니다. 실제 보증시 각 보증기관에서 심사를 받아보시기 바랍니다.",

          "보증공사 결과 금액이 0 으로 나올 경우, 금액에 따라 특정 보증기관마다 계산이 불가한 경우 입니다",

          "[ 전세금반환보증보험 ]",

          "전세계약 종료 후 임대인이 임차인에게 전세보증금을 돌려주지 못할 경우 보증회사가 이를 대신 지급하고 대위변제한 보증금은 보증회사가 임대인으로부터 회수하는  보험 상품이다.",

          "전세보증금 반환 보증 보험은 주택도시보증공사(HUG), 한국주택금융공사, 서울보증(SGI) 등이 있다. 각 기관마다 보증료율이 다르므로 자세한 보증에 대해서는 기관에 문의 바랍니다.",

          "주택도시보증공사 : http://www.khug.or.kr",

          "한국주택금융공사 : https://www.hf.go.kr",

          "서울보증보험 : https://www.sgic.co.kr",
        ]}
      />
    </>
  );
}

export default JeonseInsurance;
